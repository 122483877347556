<script setup lang="ts">
import Logan from '~/components/logan/Logan.vue';
import { useLoganStore } from '~/stores/logan';
const router = useRouter();
const LoganStore = useLoganStore();
onMounted(() => {
	if (LoganStore.isSignedIn) {
		router.replace('/');
	}
});
watch(
	() => LoganStore.isSignedIn,
	(newValue, oldValue) => {
		if (newValue && !oldValue) {
			router.replace('/');
		}
	},
);
</script>
<template>
	<div>
		<LaamMobileHeader />
		<div class="px-0 py-11xl lg:py-10xl lg:grid lg:grid-cols-12">
			<div class="lg:col-span-6 lg:col-start-3 p-4xl">
				<Logan
					:render-form-only="true"
					heading="Sign up or log in"
					:sub-heading="''"
					sign-in-with-text="Continue with"
					:show-confirmation-text="true"
				/>
			</div>
		</div>
	</div>
</template>
